@use '@angular/material' as mat;
@include mat.core();

@import 'variables';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~font-awesome/css/font-awesome.css';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($si-typography-config);`
// @include mat.all-legacy-component-typographies($si-typography-config);
// @include mat.legacy-core();

$si-typography-config: mat.m2-define-legacy-typography-config(
  $font-family: $primary-font-family
);
$si-primary: mat.m2-define-palette($primary-palette, 500);
$si-accent: mat.m2-define-palette($accent-palette, 500);
$si-warning: mat.m2-define-palette($warning-palette, 400);
$si-common-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $si-primary,
      accent: $si-accent,
      warn: $si-warning
    ),
    density: 0
  )
);

@include mat.all-component-themes($si-common-theme);
@include mat.all-component-typographies($si-typography-config);

html,
body {
  height: 100%;
}

body {
  margin: $no-margin;
  font-family: mat.m2-font-family($si-typography-config);
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Scrollbar Corner */
::-webkit-scrollbar-corner {
  background-color: transparent;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border-radius: 12px;
}

/* Scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: $scrollbar-thumb-color-hover;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

app-root {
  display: flex;
  height: 100vh;
  min-height: 100%;
}

app-login,
app-home,
app-error,
app-deliverables,
app-deliverables-draft,
app-deliverables-live,
app-deliverable-edit,
app-deliverable-list,
app-story-edit,
app-stories,
app-stories-draft,
app-stories-live,
app-story-list,
app-search,
app-deliverable-view,
app-projects,
app-projects-draft,
app-projects-live,
app-project-edit,
app-project-list,
app-projects-view,
app-reporting,
app-quick-edit,
app-kpi {
  display: flex;
  flex: 1 1 0%;
  height: 100%;
  // min-height: 100%;
}

.mat-mdc-button {
  &.mat-mdc-button-base {
    height: auto;
    .mdc-button__label {
      .ic-close,
      .icon {
        vertical-align: middle;
      }
    }
  }
}

mat-menu {
  display: block !important;
}

[hidden],
.hidden-menu {
  display: none !important;
}

.mdc-button {
  white-space: $tertiary-white-space;
}

.white-space-pre {
  white-space: $secondary-white-space;
  word-break: $primary-word-break;
  & ~ p {
    white-space: $secondary-white-space;
    word-break: $primary-word-break;
  }
}

.mat-drawer-container {
  background-color: #ffffff;
  color: $primary-text-black-dark;
}

.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      box-shadow: 0 2px 4px 0 $box-shadow-color-darker;
      border-radius: $no-border-radius !important;
    }
  }
}

.loading-wrap {
  align-items: center;
  justify-content: center;
  height: inherit;
  span {
    font-size: $primary-font-size;
    color: $primary-color;
  }
}

// toolbar
.main-page {
  .toolbar-header-row {
    padding: 30px 50px 0px;
    .toolbar-header-nav {
      border-bottom: solid 1px $border-color-darker;
      margin: 0px 0px 20px;
      .nav {
        list-style: none;
        display: flex;
        margin: $no-margin;
        padding: $no-padding;
        li {
          a {
            font-size: $primary-font-size-subtitle;
            line-height: $list-line-height;
            color: $primary-text-black-dark;
            text-transform: uppercase;
            padding: 0px 15px;
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
          }
          &.active {
            a {
              font-weight: $primary-font-weight-medium;
              &::before {
                content: '';
                position: absolute;
                left: 0px;
                right: 0px;
                height: 3px;
                bottom: -1px;
                background-color: $accent-color;
              }
            }
          }
        }
      }
      .mat-mdc-button {
        font-size: $tertiary-font-size;
        font-weight: $primary-font-weight-normal;
        color: $primary-text-black-light;
        padding: 0px 10px;
        text-transform: uppercase;
        .icon {
          font-size: $tertiary-font-size;
          color: $placeholder-color;
          margin-left: 21px;
        }
        .btn-inner {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .sort-value,
        .setting-value {
          text-transform: capitalize;
          margin: 0px 3px;
        }
        &.filter-btn {
          .icon {
            font-size: $primary-icon-size-normal;
            margin-left: 0;
            margin-right: 5px;
          }
        }
        &.sort-btn {
          min-width: 190px;
        }
        &.setting-btn {
          min-width: 100px;
        }
        &.open,
        &[aria-expanded='true'] {
          color: $primary-color;
          .icon {
            color: $primary-color;
            &:before {
              color: $primary-color;
            }
          }
        }
      }
      .search_wrap {
        input {
          width: 250px;
          height: 30px;
          border: solid 1px $border-color-dark;
          padding: 0px 35px 0px 15px;
          font-size: $tertiary-font-size;
          margin-bottom: 5px;
        }
        button {
          position: absolute;
          top: 1px;
          right: 1px;
          width: 29px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: $no-padding;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;
          .icon {
            font-size: $primary-font-size;
            color: $primary-text-black-lighter;
          }
        }
      }
    }
  }
  .list-row {
    padding: 0px 50px 30px;
    overflow: auto;
    .list-items {
      margin: 1px 0px 30px;
    }
  }
  .view-all {
    text-transform: uppercase;
    border-color: $primary-color !important;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 0 16px;
  }
  .filter-icon-btn {
    padding-top: 5px;
    padding-bottom: 5px;
    .mat-mdc-button {
      height: fit-content;
      height: -moz-fit-content;
    }
  }
}

// ng select
.ng-select {
  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        padding-left: 16px;
        .ng-value {
          white-space: $secondary-white-space;
          font-family: $primary-font-family;
          font-size: $primary-font-size-small;
        }
        .ng-input {
          padding-left: 16px;
          top: auto;
          & > input {
            font-family: $primary-font-family;
            font-size: $primary-font-size-small;
          }
        }
      }
    }
  }
}

// select deliverables
.select-deliverables-page {
  .filter-field {
    .search_wrap {
      .ng-select {
        &.ng-select-single {
          margin: 0 auto;
          max-width: 300px;
          width: 100%;
          .ng-select-container {
            height: 31px !important;
            min-height: 31px !important;
            .ng-arrow-wrapper {
              padding-right: 20px;
            }
          }
        }
      }
    }
  }
}

// Menu list after clicking button in toolbar
.live-menu {
  &.sort-menu {
    min-width: 190px !important;
    .mat-mdc-menu-item {
      padding-right: 20px;
    }
  }
  &.export-menu {
    min-width: 280px !important;
    .mat-mdc-menu-content {
      .mat-mdc-menu-item-submenu-trigger {
        .ic-arrow-down {
          &::before {
            position: absolute;
            right: 15px;
            font-size: 16px;
            display: none;
          }
        }
        .mat-mdc-menu-submenu-icon {
          display: inline-block;
        }
        &.open-menu {
          .mat-mdc-menu-submenu-icon {
            display: none;
          }
          .ic-arrow-down {
            &::before {
              display: inline-block;
            }
          }
        }
      }
      .confidential-menu {
        background-color: $dropdown-option-bg-dark;
        .mat-mdc-menu-item {
          padding: 0px 10px 0px 20px;
          .mdc-list-item__primary-text {
            font-size: $secondary-font-size;
            line-height: 24px;
          }
          &.cdk-focused {
            color: $primary-color;
          }
        }
      }
    }
  }
  box-shadow: 0 1px 4px 0 $box-shadow-color-darker !important;
  color: $primary-text-black-dark;
  .mat-mdc-menu-content {
    .mat-mdc-menu-item {
      padding: 0px 10px;
      min-height: 24px;
      .mdc-list-item__primary-text {
        line-height: $primary-line-height;
        height: 24px;
        .icon {
          font-size: $tertiary-font-size;
          position: absolute;
          right: 10px;
          top: 6px;
          span {
            position: absolute;
            top: 0px;
            left: 2.4px;
          }
        }
      }
      &[disabled] {
        opacity: $primary-disabled-opacity;
      }
    }
  }
  .mat-divider {
    margin: 10px 0px;
    border-top-color: $border-color-light;
  }
}

// menu
.menu-btn {
  &.mat-mdc-icon-button {
    --mat-mdc-button-persistent-ripple-color: transparent !important;
    .mat-ripple-element {
      opacity: $secondary-opacity;
    }
  }
}

// Dropdown & Button in filter section
.filter-wrap {
  border-radius: 4px;
  background-color: $filter-section-bg-color;
  padding: 14px 10px;
  margin: -5px 0px 15px;
  .btn-position {
    justify-content: flex-end;
  }
  label {
    font-size: $tertiary-font-size;
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  .grid {
    margin: 0px -5px;
    .form-group {
      padding: 0px 5px;
    }
  }
}

// Showing Bookmarks in filter section
.show-save-setting {
  padding-bottom: 25px;
  align-items: baseline;
  line-height: $tertiary-line-height;
  label {
    font-size: $primary-font-size;
    font-weight: $primary-font-weight-medium;
  }
  .show-name {
    padding: 0 10px;
    font-size: $secondary-font-size;
  }
}

// Apply tags in filter section
.filter-values {
  align-items: center;
  margin: 0px 0px 15px;
  label {
    font-size: $tertiary-font-size;
    margin: 0px 0px 5px;
  }
  .tag {
    height: 30px;
    border-radius: 5px;
    border: solid 1px $accent-color;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    font-size: $tertiary-font-size;
    color: $accent-color;
    margin-bottom: 5px;
    span {
      text-transform: capitalize;
    }
    .remove_tag {
      font-size: $primary-icon-size-smaller;
      margin-left: 9px;
      cursor: pointer;
    }
  }
  .clear-btn {
    width: 103px;
    height: 30px;
    border-radius: 5px;
    border: solid 0.5px $border-color-dark;
    font-size: $tertiary-font-size;
    font-weight: $primary-font-weight-normal;
    color: $primary-text-black-lighter !important;
    margin-bottom: 5px;
    &.mat-mdc-button {
      --mat-mdc-button-persistent-ripple-color: $box-shadow-color-dark;
    }
    &:hover, &:focus {
      background-color: $clear-btn-color-hover;
    }
  }
}

// Menu list after clicking filter in reporting table
.filter-menu {
  .filter-menu-group {
    .mat-mdc-checkbox {
      margin: $no-margin;
      .mdc-form-field {
        font-size: $primary-font-size;
        color: #000000;
      }
      .mdc-checkbox__native-control {
        &:enabled {
          &:checked {
            & ~ .mdc-checkbox__background {
              border-color: $accent-color !important;
              background-color: $accent-color !important;
            }
          }
        }
      }
      &.mat-mdc-checkbox-disabled {
        opacity: $primary-disabled-opacity;
      }
    }
  }
}

// form group
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  align-items: flex-start;
  position: relative;
  input {
    padding: 0px 16px;
  }
  label {
    font-size: $tertiary-font-size;
    color: $primary-text-black-light;
    margin-bottom: 7px;
    text-transform: uppercase;
    span {
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      margin-left: 2px;
    }
  }
  .hint {
    margin-top: 5px;
    font-size: $secondary-font-size;
    color: $primary-text-black-light;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    max-height: 17px;
    &.error {
      display: none;
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      &.show {
        display: block;
      }
    }
  }
  .mat-mdc-progress-bar {
    top: -3px;
    margin: 0px 4px;
    width: calc(100% - 8px);
    height: 3px;
  }
  .form-control {
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border: solid 1px $border-color-normal;
    font-size: $primary-font-size;
    line-height: $primary-line-height;
    font-family: mat.m2-font-family($si-typography-config);
    color: $primary-text-black-dark;
    outline: none;
    &:focus {
      border-color: $primary-color;
    }
    &:disabled {
      box-shadow: inset 0 1px 2px 0 $box-shadow-color-disabled;
      border: solid 1px $border-color-normal;
      background-color: $disabled-color;
    }
    &.error {
      border-color: mat.m2-get-color-from-palette($warning-palette, A100);
    }
  }
  input[type='file'] {
    display: none;
  }
  textarea {
    min-height: 250px;
    padding: 6px 16px !important;
    font-family: inherit;
    resize: vertical;
    &.short {
      min-height: 90px;
    }
  }
  .mat-autocomplete-trigger,
  .mat-mdc-autocomplete-trigger,
  select {
    position: relative;
    background-image: url(/assets/icons/dropdown.svg);
    background-position: calc(100% - 17px) 14px;
    background-repeat: no-repeat;
  }
  .mat-autocomplete-trigger {
    padding-right: 30px;
  }
  .mat-mdc-select {
    background-image: url(/assets/icons/dropdown.svg);
    background-position: calc(100% - 17px) 14px;
    background-repeat: no-repeat;
    background-color: #ffffff;
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border: solid 1px $border-color-normal;
    &:focus {
      border-color: $primary-color;
    }
    &.mat-mdc-select-disabled {
      border: solid 1px $border-color-normal;
      background-color: $disabled-color;
    }
    .mat-mdc-select-trigger {
      height: 37px;
      width: 100%;
      padding-right: 30px;
      display: inline-table;
      .mat-mdc-select-value {
        height: 37px;
        padding: $no-padding;
        line-height: 37px;
        display: table-cell;
        max-width: 0;
        .mat-mdc-select-value-text,
        .mat-mdc-select-placeholder {
          padding: 0px 16px;
          color: $primary-text-black-dark;
        }
      }
      .mat-mdc-select-arrow-wrapper {
        display: none;
      }
    }
  }
  .mat-mdc-radio-group {
    margin-left: -10px;
    .mat-mdc-radio-button {
      label {
        font-size: $primary-font-size;
        color: $primary-text-black-dark;
        text-transform: none;
        margin-bottom: 0;
      }
    }
  }
  .add-btn {
    width: 76px;
    font-size: $primary-font-size;
    font-weight: $primary-font-weight-normal;
    text-transform: uppercase;
    &.mat-mdc-outlined-button {
      &:not(:disabled) {
        border-color: $accent-color;
      }
    }
  }
  &.error {
    &>label {
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      padding-left: 30px;
      position: relative;
      &:before {
        content: '\e905';
        font-size: $primary-icon-size-normal;
        font-family: $icon-font-family;
        color: mat.m2-get-color-from-palette($warning-palette, A100);
        position: absolute;
        left: 0px;
      }
    }
    .form-control,
    .mat-mdc-select {
      border-color: mat.m2-get-color-from-palette($warning-palette, A100);
    }
  }
  &.context-error {
    iframe {
      border: 1px solid $error-color;
    }
  }
}

// chip tags
.mat-mdc-chip-set {
  &.mdc-evolution-chip-set {
    padding: $no-padding;
    .mdc-evolution-chip-set__chips {
      .mat-mdc-chip {
        &.mdc-evolution-chip {
          margin-top: 10px;
          margin-right: 2px;
          margin-bottom: 0px;
          min-height: 30px;
          height: auto;
          border-radius: 5px;
          padding: 4px 8px;
          border: solid 0.5px $accent-color;
          background-color: #ffffff !important;
          font-size: $tertiary-font-size;
          transform: translateZ(0);
          &:last-child {
            margin-right: 0px;
          }
          .mdc-evolution-chip__cell--primary {
            margin-left: 0px;
            .mdc-evolution-chip__action--primary {
              padding-left: 4px;
              padding-right: 4px;
              .mdc-evolution-chip__text-label {
                color: $accent-color;
                word-break: $primary-word-break;
                white-space: $primary-white-space;
              }
            }
          }
          .mat-mdc-chip-focus-overlay {
            background: #ffffff;
          }
          .mat-mdc-chip-remove {
            font-size: $primary-icon-size-smaller;
            color: $accent-color !important;
            width: 10px;
            height: 10px;
            opacity: $no-opacity;
            padding-left: 4px;
            padding-right: 4px;
            &:hover,
            &:focus {
              opacity: $primary-icon-opacity;
            }
          }
          .mat-chip-ripple {
            display: none;
          }
          &:active {
            box-shadow: 0 3px 3px -2px rgb(0 0 0 / 20%),
              0 3px 4px 0 rgb(0 0 0 / 14%), 0 1px 8px 0 rgb(0 0 0 / 12%);
          }
        }
      }
    }
  }
}

// snack bar
.mat-mdc-snack-bar-container {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 $box-shadow-color-normal;
  width: 330px;
  min-height: 85px;
  margin-top: 95px !important;
  margin-right: 15px !important;
  .mdc-snackbar__surface {
    min-height: 85px;
    background: transparent !important;
    padding-left: 35px !important;
    min-width: 330px !important;
  }
  .mdc-button {
    min-width: 25px;
    padding: $no-padding;
    position: absolute;
    right: -1px;
    top: 2px;
    font-weight: $primary-font-weight-normal;
    color: transparent !important;
  }
  .mdc-button__label {
    content: '';
    position: absolute;
    width: 15px;
    height: 17px;
    right: 8px;
    top: 5px;
    background-image: url(/assets/icons/close.svg);
  }
  &.success,
  &.warning,
  &.error {
    &::before {
      content: '';
      position: absolute;
      left: 20px;
      top: 35%;
      width: 25px;
      height: 25px;
    }
  }
  &.success {
    background: $success-color;
    &::before {
      background-image: url(/assets/icons/success_msg.svg);
    }
  }
  &.warning {
    background: $warning-color;
    &::before {
      background-image: url(/assets/icons/warning_msg.svg);
    }
  }
  &.error {
    background: $error-color;
    &::before {
      background-image: url(/assets/icons/error_msg.svg);
    }
  }
}

.mat-tooltip {
  font-size: $primary-font-size-small;
}

/* IE 10 and above */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .deliverable-list-row {
    display: block !important;
  }
}

.table-title {
  margin: 23px 0 23px 50px !important;
  font-size: $table-title-font-size;
  font-weight: $primary-font-weight-bolder;
}

hr {
  margin-left: 50px;
  width: 90%;
  margin-bottom: 25px;
  border: 1px solid $scrollbar-thumb-color;
}

.button-display {
  float: right;
  padding-right: 65px;
  padding-bottom: 25px;

  .mdc-button {
    font-size: $primary-font-size;
    line-height: 36px;
  }

  .add-icon {
    padding-right: 8px;
    font-size: $primary-font-size-xlarge;
    padding-bottom: 4px;
  }
}

.WavSelect {
  .ng-placeholder,
  .ng-option-disabled {
    color: $primary-text-gray !important;
  }
}

.mat-mdc-paginator {
  background-color: transparent !important;
  .mat-mdc-paginator-container {
    justify-content: flex-start;
  }
  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label {
    color: $primary-text-black-light;
  }
}

.acn-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #333333;
  opacity: $primary-disabled-opacity;
  z-index: 9999;
  color: #212529;
}

.acn-consent-dialog {
  background-color: #ffffff;
  color: #212529;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 90%;
  max-width: 800px;
  max-height: 560px;
  z-index: 9999;
  padding: 0 0 45px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  overflow: hidden;
  font-family: $primary-font-family;
}

.acn-consent-pg {
  max-width: 600px !important;
}

.acn-consent-bdy-si {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  box-sizing: border-box;
  padding: 20px;
}

.acn-consent-body {
  margin-bottom: 20px;
}

.acn-u-btn,
a.acn-u-btn:link,
a.acn-u-btn:visited {
  text-decoration: none !important;
  padding: 8px 15px;
  border: 1px solid #333333;
  color: #000000;
  background-color: #ffffff;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  font-size: 0.9em;
  cursor: pointer;
}

input.acn-u-btn.acn-consent-btn-blue.acn-consent-priv-continue {
  background: $primary-color !important;
  border: $primary-color !important;
  padding: 8px 15px !important;
  line-height: 1.3em !important;
  margin: 0 !important;
  min-width: 90px;
}

.acn-consent-btn {
  padding-top: 10px;
  text-align: right !important;
  padding-right: 40px;
}

app-admin-data-table,
app-data-table,
app-status-view,
app-stage-view {
  .mat-mdc-progress-bar {
    margin-left: 50px;
    width: 90%;
  }
}

// data table
.mat-mdc-table {
  margin-left: 50px;
  width: 90%;
  min-width: 90% !important;
  thead {
    th {
      &.mat-mdc-header-cell {
        font-family: $secondary-font-family;
        font-size: $primary-font-size-xlarge;
        padding: $no-padding;
        background: $table-bg-color;
        &:first-of-type {
          min-width: 100px;
          padding-left: 24px;
        }
        &:last-of-type {
          padding-right: 24px;
        }
        &:nth-last-child(2) {
          &:first-child {
            width: 120px;
          }
        }
      }
    }
  }
  tbody {
    td {
      font-family: $secondary-font-family;
      padding: $no-padding;
      &.highlightElement {
        cursor: pointer;
        color: $accent-color;
      }
      &.mat-mdc-cell {
        &:first-of-type {
          min-width: 100px;
          padding-left: 24px;
        }
        &:last-of-type {
          padding-right: 24px;
        }
        &:nth-last-child(2) {
          &:first-child {
            width: 120px;
          }
        }
        .active-inactive-icon {
          font-size: $tertiary-font-size;
          color: $primary-color;
          margin-left: 8px;
        }
        .shift-icon {
          margin-left: 27px;
        }
      }
    }
  }
  tfoot {
    td {
      font-family: $secondary-font-family;
      background-color: $table-bg-color;
      font-weight: $primary-font-weight-bold;
      padding: $no-padding;
      &.mat-mdc-footer-cell {
        &:first-of-type {
          min-width: 100px;
          padding-left: 24px;
        }
        &:last-of-type {
          padding-right: 24px;
        }
        &:nth-last-child(2) {
          &:first-child {
            width: 120px;
          }
        }
      }
    }
  }
  .mdc-data-table__row {
    height: 48px;
  }
}

.mat-mdc-checkbox {
  margin-left: -11px;
}

// dropdown list
.mat-mdc-option {
  .mdc-list-item__primary-text {
    white-space: $tertiary-white-space !important;
  }
  &.mdc-list-item {
    mat-pseudo-checkbox {
      display: none;
    }
  }
  
  &:hover:not(.mdc-list-item--disabled),
  &:focus:not(.mdc-list-item--disabled),
  &.mat-mdc-option-active {
    background: $dropdown-option-bg-dark;
  }
  &.mdc-list-item--selected {
    &.mat-mdc-option-multiple {
      &:not(.mdc-list-item--disabled) {
        background: $dropdown-option-bg-dark;
      }
    }
    &:not(.mat-mdc-option-multiple) {
      &:not(.mdc-list-item--disabled) {
        background: $dropdown-option-bg-light;
      }
    }
    .mdc-list-item__primary-text {
      color: $primary-text-black-dark;
    }
  }
  .active-inactive-icon {
    font-size: $primary-icon-size-smaller;
    color: $primary-color;
    padding-right: 10px;
  }
  &.shift-icon {
    padding-left: 43px;
  }
}

.switch-enable-dropdown {
  mat-option {
    font-size: $primary-font-size-small !important;
  }
}

.show-pseudo-checkbox {
  .mat-mdc-option {
    &.mdc-list-item {
      mat-pseudo-checkbox {
        display: inline-block;
      }
    }
  }
}

.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    background: $tooltip-bg-color;
    font-size: $primary-font-size-small;
    line-height: 20px;
    padding: 8px 16px;
    margin: 16px;
    max-width: 250px;
    will-change: auto;
  }
}

// button wrap
.pri-btn-edit-wrap,
.pri-btn-view-wrap,
.filter-wrap,
.related-deliverables-btn-wrap,
.selected-deliverables-btn-wrap,
.story-edit-add-btn-wrap {
  .mdc-button {
    padding: 0px 22px;
    font-size: $primary-font-size;
    font-weight: $primary-font-weight-normal;
    text-transform: uppercase;
    &[disabled] {
      color: $primary-color;
      opacity: $primary-disabled-opacity;
    }
    &.mat-mdc-unelevated-button {
      color: $primary-text-white;
      &[disabled] {
        background-color: $primary-color;
      }
    }
    &.mat-mdc-outlined-button {
      border-color: $primary-color;
    }
  }
}

.pri-btn-edit-wrap {
  margin: 0px -50px;
  padding: 50px;
  background-color: $content-bg-color;
  justify-content: flex-end;
}

.pri-btn-view-wrap {
  margin-bottom: 23px;
  .mdc-button {
    margin-bottom: 10px;
  }
}

// dialog
.add-dialog,
.confirm-dialog,
.load-setting-dialog,
.comments-dialog,
.disable-group-dialog,
.delete-dialog,
.org-type-dialog,
.deliverable-dialog,
.project-dialog,
.progress-dialog {
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding: 30px;
  .step-title {
    font-size: $tertiary-font-size;
    color: $primary-text-black-light;
    text-transform: uppercase;
  }
  .dialog-title {
    color: $primary-text-black-dark;
    text-transform: uppercase;
  }
  .dialog-content {
    p {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      color: $primary-text-black-dark;
      .delete-confirm {
        font-weight: $primary-font-weight-bold;
      }
    }
    & > p {
      margin-bottom: 25px;
      font-weight: $primary-font-weight-normal;
      &:last-child {
        margin-bottom: 30px;
      }
    }
    .form-group {
      & > p {
        margin-bottom: 5px;
        font-weight: $primary-font-weight-medium;
      }
      textarea {
        // height: 200px;
        & ~ .hint {
          position: absolute;
          right: 0;
          bottom: 0;
          margin-bottom: -20px;
        }
      }
    }
    .popup-content {
      p {
        font-size: $primary-font-size-small;
        line-height: inherit;
        &:last-child {
          margin-bottom: 30px;
        }
      }
    }
    .point {
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -20px;
    }
    ul {
      &.delete-items {
        margin: 5px -20px 30px;
      }
      &:not(.delete-items) {
        margin-top: -10px;
        margin-bottom: 30px;
        list-style: none;
        padding: $no-padding;
        &:not(:last-child) {
          margin-top: -15px;
        }
        li {
          font-size: $primary-font-size;
          line-height: $primary-line-height;
          color: $primary-text-black-dark;
        }
      }
    }
    b {
      font-weight: $primary-font-weight-medium;
    }
    .mat-mdc-progress-spinner {
      margin: 10% auto;
    }
  }
  .dialog-actions {
    justify-content: flex-end;
    .mdc-button {
      padding: 0px 22px;
      font-size: $primary-font-size;
      font-weight: $primary-font-weight-normal;
      text-transform: uppercase;
      &[disabled] {
        color: $primary-color;
        opacity: $primary-disabled-opacity;
        &.btn-delete {
          color: $warn-color;
        }
      }
      &.mat-mdc-unelevated-button {
        color: $primary-text-white;
        &[disabled] {
          background-color: $primary-color;
          &.btn-delete {
            background-color: $warn-color;
          }
        }
      }
      &.mat-mdc-outlined-button {
        border-color: $primary-color;
        &.btn-delete {
          border-color: $warn-color;
        }
      }
    }
  }
}

// admin dashboard dropdown + search + add new
.dropdown-menu {
  margin-left: 50px;
}

.checked-icon {
  font-size: $primary-icon-size-small;
  color: $primary-color;
  padding-right: 10px;
}

.search_wrap {
  align-items: center;
  position: relative;
  ::placeholder {
    color: $placeholder-color;
    font-family: $primary-font-family;
  }
  & > input {
    width: 100%;
    height: 37px;
    border-radius: 4px;
    border: solid 1px $border-color-normal;
    outline: none;
    padding: 0px 40px 0px 15px;
  }
  .icon {
    font-size: $primary-font-size-xlarge;
    color: $primary-text-black-lighter;
  }
}

// view comments
.comments-dialog {
  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    .icon {
      font-size: $primary-icon-size-normal;
      color: $primary-text-gray;
    }
  }
  .comments {
    span {
      font-size: $tertiary-font-size;
      color: $primary-text-black-light;
      margin: 0px 0px 5px;
      text-transform: uppercase;
    }
    .comment-box {
      border-radius: 4px;
      box-shadow: inset 0 1px 2px 0 $box-shadow-color-disabled;
      border: solid 1px $border-color-normal;
      background-color: $disabled-color;
      padding: 15px;
      p {
        font-weight: $primary-font-weight-normal;
      }
    }
  }
}

// list view
.list-item-card {
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 $box-shadow-color-dark;
  .name-row {
    padding: 10px;
    margin-bottom: -10px;
    a {
      padding: 10px;
      cursor: pointer;
      .list-item-image {
        width: 96px;
        height: 69px;
        border-radius: 2px;
      }
      & > div {
        h2 {
          font-size: $primary-font-size;
          line-height: $primary-line-height;
        }
        h3 {
          font-size: $tertiary-font-size;
          font-weight: $primary-font-weight-medium;
          line-height: $primary-line-height;
        }
        p {
          font-size: $tertiary-font-size;
          line-height: $list-view-line-height;
        }
        & > * {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .action-row {
      padding: 10px;
      align-items: flex-start;
      .action-row-inner {
        align-items: center;
        .tag {
          padding: 0px 20px;
          font-size: $primary-font-size;
          color: $accent-color;
          text-transform: uppercase;
          &.project-tag {
            height: 30px;
            line-height: 30px;
            margin: 0 20px;
            border: 1px solid $accent-color;
            border-radius: 4px;
          }
        }
        .menu-btn {
          .icon {
            color: $primary-text-black-light;
          }
          &[aria-expanded='true'] {
            .icon {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  mat-divider {
    border-top-color: $border-color-darker;
    margin: 0px 20px;
  }
  .data-row {
    justify-content: space-between;
    padding: 15px 20px 20px;
    .data-group {
      label {
        font-size: $tertiary-font-size;
        line-height: $primary-line-height;
        color: $primary-text-black-light;
        text-transform: uppercase;
        margin: $no-margin;
        white-space: $tertiary-white-space;
      }
      span {
        font-size: $tertiary-font-size;
        line-height: $primary-line-height;
        color: $primary-text-black-dark;
        margin: $no-margin;
      }
    }
  }
}

// deliverable & story view
.view-deliverable,
.view-story {
  padding: 30px 50px 85px;
  .value-group {
    margin-bottom: 15px;
    .label {
      width: 50%;
      font-size: $primary-font-size;
      font-weight: $primary-font-weight-medium;
      line-height: $primary-line-height;
      &.deliverable_type {
        width: 100%;
      }
    }
    .value {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      word-break: $primary-word-break;
    }
  }
  .image_wrap {
    margin-bottom: 30px;
    p {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      margin-bottom: 20px;
      word-break: $primary-word-break;
    }
  }
  .owner_wrap {
    .value-group {
      margin-bottom: 5px;
      .published-link {
        width: calc(25% - 4px);
      }
    }
    h4 {
      font-size: $primary-font-size-large;
      font-weight: $primary-font-weight-medium;
      line-height: $primary-line-height;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    mat-chip-set {
      margin: -10px 0px 25px;
    }
  }
  .link {
    font-size: $primary-font-size;
    line-height: $primary-line-height;
    color: $accent-color;
    word-break: $primary-word-break;
  }
  .project_wrap {
    padding: 25px 0px;
    .value-group {
      margin-bottom: 5px;
    }
    h4 {
      font-size: $primary-font-size-large;
      font-weight: $primary-font-weight-medium;
      line-height: $primary-line-height;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .project_name {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      margin-bottom: 5px;
    }
    .project_desc {
      font-size: $tertiary-font-size;
      line-height: $primary-line-height;
    }
    mat-chip-set {
      margin: -10px 0px 25px;
    }
  }
}

.workflow_status_wrap, .project-content-wrap {
  .workflow_status, .publish_requested_status {
    margin-bottom: 8px;
    span {
      font-size: $primary-font-size;
      line-height: $primary-line-height;
      &:first-of-type {
        color: $accent-color;
        text-transform: uppercase;
        margin-right: 5px;
      }
    }
  }
}


.owner_wrap {
  margin: 0 -50px;
  padding: 30px 50px;
  background-color: $content-bg-color;
}

.user-wrap {
  margin-bottom: 15px;
  align-items: center;
  .user-profile {
    width: 45px;
    height: 45px;
    border-radius: 45px;
    margin-right: 15px;
  }
  .user-name-wrap {
    font-size: $primary-font-size;
    line-height: $primary-line-height;
  }
}

.image_preview {
  max-width: 431px;
  width: 100%;
  height: 265px;
  box-shadow: 0 1px 2px 0 $box-shadow-color-normal;
  background-color: $image-bg-color;
  position: relative;
  &.context-error {
    border: 1px solid mat.m2-get-color-from-palette($warning-palette, A100) !important;
  }
  .image_preview_inner {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
  }
}

.label-btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
  height: 38px;
  padding: 0px 10px;
  border-radius: 4px;
  border: solid 0.8px $accent-color;
  background-color: $primary-bg-color-white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $primary-font-size !important;
  color: $accent-color !important;
  cursor: pointer;
  z-index: 2;
  .icon {
    font-size: $primary-icon-size-normal;
    color: $accent-color;
    margin-right: 10px;
  }
  &::before {
    display: none;
  }
}

// related deliverables table
.related-deliverables-wrap {
  border: 1px solid $border-color-light;
  table {
    display: flex;
    flex-direction: column;
    thead {
      tr {
        box-shadow: 0 1px 0 0 $border-color-light;
        display: flex;
        th {
          height: 44px;
          background-color: mat.m2-get-color-from-palette($primary-palette, A100);
          font-size: $tertiary-font-size;
          font-weight: $primary-font-weight-medium;
          line-height: $secondary-line-height;
          color: $primary-text-black-light;
          text-transform: uppercase;
          padding: 0px 30px;
          display: flex;
          align-items: center;
          flex: 1;
          &:first-of-type {
            min-width: 40%;
          }
        }
      }
    }
    tbody {
      max-height: 210px;
      overflow: auto;
      tr {
        display: flex;
        box-shadow: 0 1px 0 0 $border-color-light;
        td {
          font-size: $primary-font-size-small;
          line-height: $secondary-line-height;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          flex: 1;
          &:first-of-type {
            min-width: 40%;
          }
          .deliverable-image {
            width: 64px;
            height: 46px;
            border-radius: 4px;
          }
          h6 {
            font-size: $primary-font-size-small;
            font-weight: $primary-font-weight-medium;
            line-height: $secondary-line-height;
            margin: $no-margin;
          }
          p {
            font-size: $tertiary-font-size;
            line-height: $primary-line-height;
            margin: $no-margin;
          }
        }
      }
    }
  }
}

// edit page
.edit-deliverable,
.edit-project,
.edit-story {
  padding: 30px 50px 0px;
  h2 {
    font-size: $primary-common-h2-title;
    line-height: $primary-line-height;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  p {
    font-size: $tertiary-font-size;
    line-height: $primary-line-height;
    margin-bottom: 30px;
    span {
      color: mat.m2-get-color-from-palette($warning-palette, A100);
      margin-right: 2px;
    }
  }
  .project_wrap {
    .disable_font {
      ::ng-deep span {
        color: $disabled-text-color;
      }
    }
  }
  mat-divider {
    border-top-color: $border-color-lighter;
    margin-bottom: 20px;
  }
  .hintMessage {
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: $primary-font-size;
    color: $primary-text-black-light;
    margin-bottom: -30px;
  }
}

// login & error page
.single-page {
  background-color: mat.m2-get-color-from-palette($accent-palette, 50);
  justify-content: center;
  .single-page-box-wrap {
    width: 100%;
    max-width: 450px;
    border-radius: 10px;
    box-shadow: 0 25px 50px 0 $box-shadow-color-lighter;
    background-color: #ffffff;
    padding: 50px;
    margin: $no-margin auto;
    .single-page-header {
      align-items: center;
      margin-bottom: 40px;
      h1 {
        font-size: $primary-common-h1-title;
        font-weight: $primary-font-weight-bolder;
        line-height: 1.06;
        color: $primary-text-black-dark;
        margin: $no-margin;
        &.center {
          text-align: center;
        }
      }
    }
    .single-page-content {
      p {
        font-size: $primary-font-size-large;
        line-height: $normal-line-height;
        text-align: center;
        color: $primary-text-gray;
        margin: 0px 0px 20px;
      }
    }
    .single-page-bottom {
      button {
        height: 45px;
        border-radius: 6px;
        font-size: $primary-font-size-large;
        font-weight: $primary-font-weight-normal;
        margin: 28px 0 0;
      }
    }
  }
}
