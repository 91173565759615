
@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local('Graphik Thin'), local('Graphik-Thin'), url('/assets/fonts/graphik/Graphik-Thin.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local('Graphik Thin Italic'), local('Graphik-ThinItalic'), url('/assets/fonts/graphik/Graphik-ThinItalic.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Graphik Extra Light'), local('Graphik-Extralight'), url('/assets/fonts/graphik/Graphik-Extralight.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Graphik Extra Light Italic'), local('Graphik-ExtralightItalic'), url('/assets/fonts/graphik/Graphik-ExtralightItalic.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Graphik Light'), local('Graphik-Light'), url('/assets/fonts/graphik/Graphik-Light.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Graphik Regular'), local('Graphik-Regular'), url('/assets/fonts/graphik/Graphik-Regular.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Graphik Regular Italic'), local('Graphik-RegularItalic'), url('/assets/fonts/graphik/Graphik-RegularItalic.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Graphik Medium'), local('Graphik-Medium'), url('/assets/fonts/graphik/Graphik-Medium.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Graphik Medium Italic'), local('Graphik-MediumItalic'), url('/assets/fonts/graphik/Graphik-MediumItalic.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Graphik Semi Bold'), local('Graphik-Semibold'), url('/assets/fonts/graphik/Graphik-Semibold.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Graphik Bold'), local('Graphik-Bold'), url('/assets/fonts/graphik/Graphik-Bold.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Graphik Bold Italic'), local('Graphik-BoldItalic'), url('/assets/fonts/graphik/Graphik-BoldItalic.woff')format('woff');
}


@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Graphik Super'), local('Graphik-Super'), url('/assets/fonts/graphik/Graphik-Super.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local('Graphik Super Italic'), local('Graphik-SuperItalic'), url('/assets/fonts/graphik/Graphik-SuperItalic.woff')format('woff');
}

@font-face {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Graphik Black'), local('Graphik-Black'), url('/assets/fonts/graphik/Graphik-Black.woff')format('woff');
}
